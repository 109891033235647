import React from "react";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";

type FieldType = {
  email?: string;
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const onFinish = (values: any) => {
    navigate("/reset-password");
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Forgot Password</h1>
      <p className="mb-4">Enter your email to reset your password</p>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          label="Email"
          layout="vertical"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
          className="mb-12 sm:mb-14 "
        >
          <Input className="bg-slate-100 h-10" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="w-full">
            Send Link
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ForgotPassword;
