import React from "react";

import type { FormProps } from "antd";
import { Button, Form, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const LoginForm: React.FC = () => {
  const navigate = useNavigate();

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    navigate("/dashboard");
    console.log("Success:", values);
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo,
  ) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <div>
        <h1 className="text-2xl font-bold mb-4">Welcome Back!</h1>
        <p className="mb-4">Login to your account</p>
      </div>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          label="Username"
          layout="vertical"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
          className="mb-12 sm:mb-14 "
        >
          <Input className="bg-slate-100 h-10" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Password"
          name="password"
          layout="vertical"
          rules={[{ required: true, message: "Please input your password!" }]}
          className="sm:mb-14 "
        >
          <Input.Password className="bg-slate-100 h-10" />
        </Form.Item>

        <div className=" flex items-center justify-end p-3 ">
          <Link to="/forgot-password" className="text-blue-500">
            Forgot password?
          </Link>
        </div>

        <Form.Item className="mb-3">
          <Button type="primary" htmlType="submit" className="w-full">
            Login
          </Button>
        </Form.Item>
      </Form>

      <div className=" flex items-center justify-end p-3 gap-2 ">
        Don't have an account?{" "}
        <Link to="/register" className="text-blue-500">
          Register
        </Link>
      </div>
    </div>
  );
};

export default LoginForm;
