import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import {
  ForgotPassword,
  LoginForm,
  Register,
  ResetPassword,
} from "./components";

import { AuthPage, Dashboard } from "./pages";
function App() {
  return (
    <Routes>
      <Route path="/" element={<AuthPage />}>
        <Route index element={<LoginForm />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/register/:financialServiceProvider"
          element={<Register />}
        />
        <Route path="/register" element={<Register />} />
      </Route>
      <Route path="/dashboard" element={<Dashboard />} />
    </Routes>
  );
}

export default App;
