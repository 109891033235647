import React from "react";
import { Outlet } from "react-router-dom";
import Image from "../../assets/images/investment-5241253_1280.jpg";

const Auth = () => {
  return (
    <div className="flex h-screen items-center gap-4  w-full">
      <div className="w-full p-6 md:w-3/2">
        <div className="w-full p-2 md:w-2/3 m-auto">
          <Outlet />
        </div>
      </div>
      <div
        className="bg-blue-400 rounded-lg h-full md:w-full hidden sm:block"
        style={{
          backgroundImage: `url(${Image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-black bg-opacity-50 h-full w-full flex items-center justify-center p-4">
          <div className="text-white text-center">
            <h1 className="text-4xl font-bold mb-4">Welcome to our platform</h1>
            <p className="mb-4">Invest in your future today</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
