import React from "react";
import { Button, Form, Input } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";

type FieldType = {
  username?: string;
  password?: string;
  email?: string;
  phone?: string;
  confirmPassword?: string;
};

const Register = () => {
  const navigate = useNavigate();
  const { financialServiceProvider } = useParams();
  const onFinish = (values: any) => {
    navigate("/");
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Create an account</h1>
      <p className="mb-4">Enter your details below to create your account</p>
      <Form
        name="basic"
        initialValues={{ remember: true, fsp: financialServiceProvider }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Financial Service Provider"
          layout="vertical"
          name="fsp"
          rules={[
            { required: true, message: "Please input your financial service" },
          ]}
          className="mb-12 sm:mb-14 "
        >
          <Input className="bg-slate-100 h-10" />
        </Form.Item>
        <Form.Item
          label="Full Name"
          layout="vertical"
          name="fullname"
          rules={[{ required: true, message: "Please input your full name!" }]}
          className="mb-12 sm:mb-14 "
        >
          <Input className="bg-slate-100 h-10" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Email"
          layout="vertical"
          name="email"
          rules={[{ required: true, message: "Please input your username!" }]}
          className="mb-12 sm:mb-14 "
        >
          <Input className="bg-slate-100 h-10" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Phone Number"
          layout="vertical"
          name="phone"
          rules={[{ required: true, message: "Please input your username!" }]}
          className="mb-12 sm:mb-14 "
        >
          <Input className="bg-slate-100 h-10" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Password"
          name="password"
          layout="vertical"
          rules={[{ required: true, message: "Please input your password!" }]}
          className="sm:mb-14 "
        >
          <Input.Password className="bg-slate-100 h-10" />
        </Form.Item>
        <Form.Item<FieldType>
          label="Confirm Password"
          name="confirmPassword"
          layout="vertical"
          rules={[{ required: true, message: "Please input your password!" }]}
          className="sm:mb-14 "
        >
          <Input.Password className="bg-slate-100 h-10" />
        </Form.Item>

        <Form.Item className="mb-3 sm:mt-6">
          <Button type="primary" htmlType="submit" className="w-full">
            Create Account
          </Button>
        </Form.Item>
      </Form>
      <div className="flex items-center justify-end p-3 gap-2">
        Already have an account?{" "}
        <Link to="/" className="text-blue-500">
          Login
        </Link>
      </div>
    </div>
  );
};

export default Register;
