import { Button, Form, FormProps, Input } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

type FieldType = {
  newPassword?: string;
  confirmPassword?: string;
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    navigate("/");
    console.log("Success:", values);
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo,
  ) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Reset Password</h1>
      <p className="mb-4">Enter your email to reset your password</p>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          label="New Password"
          layout="vertical"
          name="newPassword"
          rules={[{ required: true, message: " Please input new password " }]}
          className="mb-12 sm:mb-14 "
        >
          <Input.Password className="bg-slate-100 h-10" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Confirm Password"
          name="confirmPassword"
          layout="vertical"
          rules={[{ required: true, message: "Please confirm your password!" }]}
          className="sm:mb-14 "
        >
          <Input.Password className="bg-slate-100 h-10" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="w-full">
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetPassword;
